<template>
  <div class="app-home">
    <div class="app-home-head" v-if="!hideHead">
      File Management
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div class="app-home-table">
      <el-row style="padding: 10px">
        <el-col :span="5">
          <span>Item Code：</span>
          <el-input v-model="params.itemCode" placeholder="Item Code" style="width:210px;"></el-input>
        </el-col>
        <el-col :span="5">
          <span>Product Name：</span>
          <el-input v-model="params.productName" placeholder="Product Name" style="width:210px;"></el-input>
        </el-col>
        <!-- <el-col :span="5">
          <span>Lot Number：</span>
          <el-input v-model="params.lotNumber" placeholder="Lot Number" style="width:210px;"></el-input>
        </el-col>
        <el-col :span="5" v-if="isShowUpload">
          <span>Type：</span>
          <el-select v-model="params.type" clearable placeholder="select Type">
            <el-option
              v-for="item in categoryList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :span="2">
          <div>
            <el-button icon="el-icon-search" @click="handleSearch('查询')" style="float: left">Search</el-button>
          </div>
        </el-col>
      </el-row>

      <div
        :class="[{ 'table-list': true }, screen_ratio]"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="Desperately loading..."
        style="height: 77vh"
      >
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
        >
        <el-table-column type="index" label="#" width="60"></el-table-column>
        <el-table-column prop="itemCode" label="Item Code"></el-table-column>
        <el-table-column prop="productName" label="Product Name"></el-table-column>
        <el-table-column label="Spec" style="text-align: center;">
          <template slot-scope="scope">
            <el-button v-show="scope.row.specUrl" size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row,'spec')" style="margin-bottom:5px;"></el-button> <br/>
            <el-button v-show="scope.row.specUrl" size="mini"  icon="el-icon-document-copy" circle
            v-clipboard:copy="scope.row.specUrl" 
            v-clipboard:success="onCopy" 
            v-clipboard:error="onError" style="margin-bottom:5px;"></el-button><br/>
            <el-button size="mini" v-show="!hideHead && scope.row.specUrl && userInfo.operateStatus" type="danger" icon="el-icon-delete" circle @click="handleDelete(scope.row,'spec')"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="SDS">
          <template slot-scope="scope">
            <el-button v-show="scope.row.sdsUrl" size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row,'sds')" style="margin-bottom:5px;"></el-button> <br/>
            <el-button v-show="scope.row.sdsUrl" size="mini"  icon="el-icon-document-copy" circle
            v-clipboard:copy="scope.row.sdsUrl" 
            v-clipboard:success="onCopy" 
            v-clipboard:error="onError" style="margin-bottom:5px;"></el-button><br/>
            <el-button size="mini" v-show="!hideHead && scope.row.sdsUrl && userInfo.operateStatus" type="danger" icon="el-icon-delete" circle @click="handleDelete(scope.row,'sds')"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Composition">
          <template slot-scope="scope">
            <el-button v-show="scope.row.compositionUrl" size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row,'composition')" style="margin-bottom:5px;"></el-button> <br/>
            <el-button v-show="scope.row.compositionUrl" size="mini"  icon="el-icon-document-copy" circle
            v-clipboard:copy="scope.row.compositionUrl" 
            v-clipboard:success="onCopy" 
            v-clipboard:error="onError" style="margin-bottom:5px;"></el-button><br/>
            <el-button size="mini" v-show="!hideHead && scope.row.compositionUrl && userInfo.operateStatus" type="danger" icon="el-icon-delete" circle @click="handleDelete(scope.row,'composition')"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="CoA">
          <template slot-scope="scope">
            <el-button size="mini" icon="el-icon-view" v-show="scope.row.coa.length>0"  circle @click="handlePreviewCoa(scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Other Documents">
          <template slot-scope="scope">
            <el-button size="mini" icon="el-icon-folder-add" circle @click="showUploadFile(scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Action" width="100">
          <template slot-scope="scope">
            <el-button size="mini" v-show="!hideHead && userInfo.operateStatus" type="danger" icon="el-icon-delete" circle @click="handleItemCodeDelete(scope.row)"></el-button>
          </template>
        </el-table-column>
        </el-table>

        <div class="table-pagition">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageIndex"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <div>
        <el-dialog width="80%" title="Coa File" :visible.sync="dialogTableVisible">
          <el-table :data="coaData">
            <el-table-column property="itemCode" label="Item Code"></el-table-column>
            <el-table-column property="productName" label="Product Name"></el-table-column>
            <el-table-column property="lotNumber" label="Lot #"></el-table-column>
            <el-table-column property="MfcDate" label="Mfc Date"></el-table-column>
            <el-table-column label="File">
              <template slot-scope="scope">
                <a style="color: red;" v-show="scope.row.pdfUrl!=''" :href="scope.row.pdfUrl" target="_blank">{{scope.row.pdfName}}</a>
              </template>
            </el-table-column>
            <el-table-column property="inventoryType" label="Inventory">
              <template slot-scope="scope">
                <el-tag  v-show="scope.row.inventoryType!=2">Inventory</el-tag>
                <el-tag type="success" v-show="scope.row.inventoryType==2">Sample Inventory</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Order Status">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.orderStatus==1">完成</el-tag>
                <el-tag type="info" v-else>未完成</el-tag>
              </template>
            </el-table-column>
            <el-table-column property="orderTime" label="Order Time"></el-table-column>
            <el-table-column label="Action">
              <template slot-scope="scope">
                <el-button size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row,'coa')" style="margin-bottom:5px;"></el-button>
                <el-button size="mini" v-show="!hideHead && userInfo.operateStatus" type="danger" icon="el-icon-delete" circle @click="handleDelete(scope.row,'coa')"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogTableVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
          </div>
        </el-dialog>
      </div>
      <div>
        <el-dialog title="Upload File" :visible.sync="otherDocumentVisible">
          <el-row style="padding: 10px">
            <el-col :span="10" v-if="isShowUpload">
              <span>Document Type：</span>
              <el-select v-model="documentType" clearable placeholder="select Document Type">
                <el-option
                  v-for="(item,index) in documentTypeList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" v-if="userInfo.operateStatus">
              <div>
                <el-upload
                  class="upload-demo"
                  action="#"
                  v-show="!hideHead"
                  :http-request="uploadFile"
                  :auto-upload="true"
                  :multiple="true"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="success">Upload<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <el-table :data="otherDocumentData">
            <el-table-column property="itemCode" label="Item Code"></el-table-column>
            <el-table-column property="category" label="Document Type"></el-table-column>
            <el-table-column property="name" label="FileName"></el-table-column>
            <el-table-column label="Action">
              <template slot-scope="scope">
                <el-button size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row,'other')" style="margin-bottom:5px;"></el-button>
                <el-button size="mini"  icon="el-icon-document-copy" circle
                v-clipboard:copy="scope.row.url" 
                v-clipboard:success="onCopy" 
                v-clipboard:error="onError" style="margin-bottom:5px;"></el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleOtherDocumentDelete(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button @click="otherDocumentVisible = false">取 消</el-button>
            <el-button type="primary" @click="otherDocumentVisible = false">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
export default {
  mixins: [base],
  data() {
    return {
      loading: false,
      hideHead:false,
      dialogTableVisible:false,
      tableData: [],
      coaData:[],
      total: 0,
      params: {
        pageIndex: 1,
        pageSize: 100,
        itemCode: "",
        productName: "",
        lotNumber: "",
        type: "",
      },
      screen_ratio: "",
      categoryList:[
        {
          label: "spec",
          value: "spec",
        },
        {
          label: "coa",
          value: "coa",
        },
        {
          label: "sds",
          value: "sds",
        },
        {
          label: "composition",
          value: "composition",
        },
      ],
      isShowUpload:true,
      source:'',
      url:'',
      documentTypeList:[
        'Aflatoxin Statement',
        'Allergen Statement',
        'Animal Free Statement',
        'Animal Testing Statement',
        'Authenticity Statement',
        'BSE Statement',
        'CA Transparency Statement',
        'CITES Statement',
        'Clean and Non Toxic Statement',
        'CMR Statement',
        'Composition Breakdown Statement',
        'Continue Guarantee',
        'COV Statement',
        'Description Identification Letter',
        'DSL List',
        'Efficacy Data Statement',
        'EU_EC Statement',
        'Extraction Ratio Statement',
        'Food_Grade Statement',
        'Fragrance allergen Statement',
        'FSMA_21CFR117',
        'Gelatin Free Statement',
        'Global Compliance Statement',
        'Gluten Free Statement',
        'GMP Statement',
        'GRAS Status Statement',
        'HACCP Statement',
        'HALAL Statement',
        'HTS Statement',
        'HTS Statement',
        'IFRA Statement',
        'INCI Statement',
        'Non-GMO statement',
        'Other document....'
      ],
      documentType:'',
      otherDocumentVisible:false,
      otherDocumentData:[],
      otherDocumentDetail:{},
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      if (userInfo) {
        localStorage.setItem("loginUserNameKey", userInfo.username);
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    back() {
      this.$router.push({ path: "/home" });
    },
    getTime(time) {
      if (time) {
        return this.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    confirm() {
      this.$confirm("你确定要退出吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    logout() {
      let token = localStorage.getItem("specToken");
      let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
      this.$api
        .logout({ id, token })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$message({ type: "success", message: "退出成功" });
          localStorage.clear("specToken");
          localStorage.clear("specUserInfo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getData(this.params);
    },
    handlePreview(row,type) {
      console.log(row,type)
      if(type == 'spec'){
        this.$router.push({ path: "library-preview?id=" + row.spec.fileId + (this.hideHead ? '&source=' + this.source : '') });
      }
      else if(type == 'sds'){
        this.$router.push({ path: "library-preview?id=" + row.sds.fileId + (this.hideHead ? '&source=' + this.source : '') });
      }
      else if(type == 'composition'){
        this.$router.push({ path: "library-preview?id=" + row.composition.fileId + (this.hideHead ? '&source=' + this.source : '') });
      }
      else if(type == 'coa'){
        this.$router.push({ path: "library-preview?id=" + row.id + (this.hideHead ? '&source=' + this.source : '') });
      }
      else if(type == 'other'){
        this.$router.push({ path: "file-preview",query: { fileUrl: row.url } });
      }
    },
    handlePreviewCoa(row){
      console.log(row)
      this.coaData = row.coa
      this.coaData.forEach(item=>{
        let coa = JSON.parse(item.coa)
        console.log('coa')
        console.log(coa)
        // item.coa = coa
        item.itemCode = coa.itemCode
        item.lotNumber = coa.lot
        item.productName = coa.productName
        let jsonValue = JSON.parse(coa.jsonValue)
        console.log(jsonValue)
        if(jsonValue.pdfUrl){
          let pdfJson =  JSON.parse(jsonValue.pdfUrl).pdfUrl
          item.pdfName = pdfJson.fileName
          item.pdfUrl = pdfJson.url
        }
        else{
          item.pdfName = ''
          item.pdfUrl = ''
        }
        item.MfcDate = jsonValue.BaseInfoManufacturingDate
        item.coaUrl = this.url + "library-preview?id="+item.id + (this.hideHead ? '&source=' + this.source : '')
      })
      this.dialogTableVisible =true
      console.log('this.coaData')
      console.log(this.coaData)
    },
    showUploadFile(row){
      console.log(row)
      this.otherDocumentVisible = true
      this.otherDocumentDetail = row
      this.otherDocumentData = this.otherDocumentDetail.other
      this.otherDocumentData.forEach(element=>{
        element.itemCode = row.itemCode
        element.category = element.category.replace('other-','')
      })
    },   
    onCopy () {// 复制成功时的回调函数
      this.$message.success("Content copied to clipboard!")
    },
    onError () {// 复制失败时的回调函数
      this.$message.error("Sorry, copy failed!")
    },
    getData(params) {
      this.loading = true;
      this.$api.getFileManagementPageList(params)
        .then((data) => {
          console.log(data);
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.tableData.forEach(item=>{
            if(item.spec){
              item.specUrl = this.url + "library-preview?id="+item.spec.fileId
              item.spec.jsonValue = JSON.parse(item.spec.jsonValue)
            }
            else{
              item.specUrl =''
            }
            if(item.sds){
              item.sdsUrl = this.url + "library-preview?id="+item.sds.fileId
              item.sds.jsonValue = JSON.parse(item.sds.jsonValue)
            }
            else{
              item.sdsUrl =''
            }
            if(item.composition){
              item.compositionUrl = this.url + "library-preview?id="+item.composition.fileId
              item.composition.jsonValue = JSON.parse(item.composition.jsonValue)
            }
            else{
              item.compositionUrl =''
            }
          })
          
          console.log(this.tableData);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSearch(type) {
      switch (type) {
        case "重置":
          {
            this.searchData = { productName: "", type: "", itemCode: "" };
          }
          break;
        case "查询":
          {
            this.params.pageIndex = 1;
            this.getData(this.params);
          }
          break;
        default:
          break;
      }
    },
    uploadFile(file) {
      if(this.documentType==""){
          this.$message.error("Please Select Document Type");
          this.$refs['upload'].clearFiles();
          return false
      }
      console.log("======uploadFile=====");
      console.log(file);
      var last = file.file.name.split('.')[file.file.name.split('.').length - 1];//取最后一个，就是文件名
      let name = file.file.name.substr(0, file.file.name.length-last.length-1);
      var filelist = new FormData();
      filelist.append("file", file.file);
      filelist.append("category", 'other-'+this.documentType);
      filelist.append("fileName", name);
      filelist.append("itemCode", this.otherDocumentDetail.itemCode);
      console.log(name)
      this.$api.libraryUploadFile(filelist).then((data) => {
        console.log(data);
        this.$message({
            message: "Attachment uploaded successfully",
            type: "success",
        });
        this.otherDocumentData.push({
          itemCode:this.otherDocumentDetail.itemCode,
          url:data.url,
          name:data.fileName,
          category:this.documentType
        })
        this.getData(this.params);
      })
      .catch((err) => {
          console.log(err);
          this.$message.error("Network exception, please try again");
          // this.$refs['upload'].clearFiles();
      });
    },
    handleDelete(row,type){
      let rowId = 0 
      console.log(type)
      if(type == 'spec'){
        rowId = row.spec.fileId
      }
      else if(type == 'sds'){
        rowId = row.sds.fileId
      }
      else if(type == 'composition'){
        rowId = row.composition.fileId
      }
      else if(type == 'coa'){
        rowId = row.id
      }
      this.$confirm(
        "This will permanently delete the data. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.$api.deleteLibraryInfo(rowId).then((data) => {
          console.log(data);
          this.$message({ type: "success", message: "Delete SuccessFully" });
          this.dialogTableVisible = false
          this.getData(this.params);
        })
        .catch((err) => {
          console.log(err);
        });
      });
    },
    handleItemCodeDelete(row){
      this.$confirm(
        "This will permanently delete the data. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.$api.deleteLibraryByItemcode(row.itemCode).then((data) => {
          console.log(data);
          this.$message({ type: "success", message: "Delete SuccessFully" });
          this.getData(this.params);
        })
        .catch((err) => {
          console.log(err);
        });
      });
    },
    handleOtherDocumentDelete(row){
      this.$confirm(
        "This will permanently delete the data. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.$api.deleteLibraryInfo(row.id).then((data) => {
          console.log(data);
          this.$message({ type: "success", message: "Delete SuccessFully" });
          this.getData(this.params);
          this.otherDocumentVisible = false
        })
        .catch((err) => {
          console.log(err);
        });
      });
    },
  },
  mounted() {
    let url = window.location.href.replace('file','')
    this.url = url
    let source = this.$route.query.source;
    if(source){
      this.hideHead = true
      this.source = source
    }
  },
  created() {
    this.getData(this.params);
    let sheight = window.screen.height;
    let swidth = window.screen.width;
    let ratio = swidth / sheight;
    switch (ratio) {
      case 16 / 9:
        this.screen_ratio = "ratio-16-9";
        break;
      case 16 / 10:
        this.screen_ratio = "ratio-16-10";
        break;
      case 3 / 2:
        this.screen_ratio = "ratio-3-2";
        break;
      case 4 / 3:
        this.screen_ratio = "ratio-4-3";
        break;
      default:
        this.screen_ratio = "ratio-16-10";
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>